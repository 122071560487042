import React, { useMemo, useState } from "react"
import axios from "axios";
import { BASE_API_URL } from '../../utils/constants';

import { Container, Table, TableBody, TableCell, TableContainer, TableRow, TextField, Button, TableHead, FormControl, InputLabel, Select, MenuItem, Box } from '@material-ui/core';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const EstoreTemplate = () => {
    const [companyName, setCompanyName] = useState('');
    const [group, setGroup] = useState('');
    const [phase, setPhase] = useState('');
    const [sid, setSid] = useState('');
    const [note, setNote] = useState('<p>1- These Params should be used for the main page only</p><ul><li>Example: <code style="background-color: var(--ds--code--bg-color,var(--ds-background-neutral, #F4F5F7)); color: var(--ds-text, #172B4D);">amdtx.com/{estore_name}?group=group1&amp;phase=phase1&amp;sid=ABCD0001</code></li></ul><p>2- Depending on the parameters, the default language changes.</p><ul><li>By using groups=<strong>group1 </strong>The eStore’s default language will be <strong>English</strong>,</li><li>By using groups=<strong>group2 </strong>The eStore’s default language will be <strong>French</strong>,</li></ul>');
    const [reconfiguration, setReconfiguration] = useState('');

    const [themeColor, setThemeColor] = useState('#F9B257');
    const [selectedLanguages, setSelectedLanguages] = useState([]);
    const [slogan, setSlogan] = useState('EN: Slogan (by EN)<br>FR: Slogan (by FR)');
    const [description, setDescription] = useState('EN: Description (by EN)<br>FR: Description (by FR)');
    const [redeemButton, setRedeemButton] = useState('EN: Access AmDTx (by EN)<br>FR: Accéder à AmDTx (by FR)');
    const [signupSlogan, setSignupSlogan] = useState('EN: Description (by EN)<br>FR: Description (by FR)');
    const [freePremiumAccess, setFreePremiumAccess] = useState('');
  
    const toolbar = [
      [{ 'header': '1' }, { 'header': '2' }, { 'font': [] }],
      [{ 'list': 'ordered'}, { 'list': 'bullet' }],
      ['bold', 'italic', 'underline'],
      ['link'],
      ['blockquote'],
      [{ 'align': [] }],
      ['image'],
    ];

    const handleLanguageChange = (event) => {
      setSelectedLanguages(event.target.value);
    };
  
    const handleThemeColorChange = (event) => {
      setThemeColor(event.target.value);
    };
  
    const submit = async () => {
      let data = {
        companyName: companyName,
        group: group,
        phase: phase,
        sid: sid,
        selectedLanguages: selectedLanguages.toString(),
        note: note,
        reconfiguration: reconfiguration,
        themeColor: themeColor,
        slogan: slogan,
        redeemButton: redeemButton,
        signupSlogan: signupSlogan,
        freePremiumAccess: freePremiumAccess
      }

      const res = await axios.post(
        BASE_API_URL + '/estore-template', data,
      );

      if (res.status === 200) {
        setCompanyName('');
        setGroup('');
        setPhase('');
        setSid('');
        setNote('');
        setThemeColor('');
        setSelectedLanguages([]);
        setReconfiguration('');
        setSlogan('<p>1- These Params should be used for the main page only</p><ul><li>Example: <code style="background-color: var(--ds--code--bg-color,var(--ds-background-neutral, #F4F5F7)); color: var(--ds-text, #172B4D);">amdtx.com/{estore_name}?group=group1&amp;phase=phase1&amp;sid=ABCD0001</code></li></ul><p>2- Depending on the parameters, the default language changes.</p><ul><li>By using groups=<strong>group1 </strong>The eStore’s default language will be <strong>English</strong>,</li><li>By using groups=<strong>group2 </strong>The eStore’s default language will be <strong>French</strong>,</li></ul>');
        setDescription('EN: Description (by EN)<br>FR: Description (by FR)');
        setRedeemButton('EN: Access AmDTx (by EN)<br>FR: Accéder à AmDTx (by FR)');
        setSignupSlogan('EN: Description (by EN)<br>FR: Description (by FR)');
        setFreePremiumAccess('');
        toast.success("SUCCESSFUL")
      } else {
        toast.error("ERROR");
      }
}

    return (
      <Container maxWidth="lg" style={{ paddingTop: '50px', paddingBottom: '50px' }}>
        <Box display="flex" justifyContent="center" alignItems="center" flexDirection="column">
          <h2>Create Estore</h2>
          <TableContainer style={{ width: '100%' }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell><strong>Field Name</strong></TableCell>
                  <TableCell><strong>Value</strong></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell>Company Name</TableCell>
                  <TableCell>
                    <TextField
                      label="Enter Company Name"
                      variant="outlined"
                      fullWidth
                      value={companyName}
                      onChange={(e) => setCompanyName(e.target.value)}
                    />
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell>Group (Optional)</TableCell>
                  <TableCell>
                    <TextField
                      label="Enter Group (seperated by ;). Example: group1;group2"
                      variant="outlined"
                      fullWidth
                      value={group}
                      onChange={(e) => setGroup(e.target.value)}
                    />
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell>Phase (Optional)</TableCell>
                  <TableCell>
                    <TextField
                      label="Enter Phase (seperated by ;). Example: phase1;phase2"
                      variant="outlined"
                      fullWidth
                      value={phase}
                      onChange={(e) => setPhase(e.target.value)}
                    />
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell>SID format (Optional)</TableCell>
                  <TableCell>
                    <TextField
                      label="Enter SID format. Example: Four letters in uppercase followed by four numbers (ABCD0001)"
                      variant="outlined"
                      fullWidth
                      value={sid}
                      onChange={(e) => setSid(e.target.value)}
                    />
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell>Language Support</TableCell>
                  <TableCell>
                    <FormControl fullWidth variant="outlined">
                      <InputLabel>Choose Languages</InputLabel>
                      <Select
                        multiple
                        value={selectedLanguages}
                        onChange={handleLanguageChange}
                        label="Choose Languages"
                      >
                        <MenuItem value="EN">EN</MenuItem>
                        <MenuItem value="FR">FR</MenuItem>
                        <MenuItem value="NL">NL</MenuItem>
                        <MenuItem value="DE">DE</MenuItem>
                      </Select>
                    </FormControl>
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell>Note (Optional)</TableCell>
                  <TableCell>
                    <ReactQuill
                      value={note}
                      onChange={setNote}
                      modules={{
                        toolbar: toolbar
                      }}
                    />
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell>Reconfiguration</TableCell>
                  <TableCell>
                    <ReactQuill
                      value={reconfiguration}
                      onChange={setReconfiguration}
                      modules={{
                        toolbar: toolbar
                      }}
                    />
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell>Theme Color</TableCell>
                  <TableCell>
                    <Box display="flex" alignItems="center">
                      <div
                        style={{
                          width: 20,
                          height: 20,
                          backgroundColor: themeColor,
                          marginRight: 10
                        }}
                      />
                      <TextField
                        type="color"
                        value={themeColor}
                        onChange={handleThemeColorChange}
                      />
                    </Box>
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell>Landing Page Slogan</TableCell>
                  <TableCell>
                    <ReactQuill
                      value={slogan}
                      onChange={setSlogan}
                      modules={{
                        toolbar: toolbar
                      }}
                    />
                  </TableCell>
                </TableRow>
  
                <TableRow>
                  <TableCell>Landing Page Description</TableCell>
                  <TableCell>
                    <ReactQuill
                      value={description}
                      onChange={setDescription}
                      modules={{
                        toolbar: toolbar
                      }}
                    />
                  </TableCell>
                </TableRow>
    
                <TableRow>
                  <TableCell>Redeem Button Text</TableCell>
                  <TableCell>
                    <ReactQuill
                      value={redeemButton}
                      onChange={setRedeemButton}
                      modules={{
                        toolbar: toolbar
                      }}
                    />
                  </TableCell>
                </TableRow>
  
                <TableRow>
                  <TableCell>Signup/Signin Slogan</TableCell>
                  <TableCell>
                    <ReactQuill
                      value={signupSlogan}
                      onChange={setSignupSlogan}
                      modules={{
                        toolbar: toolbar
                      }}
                    />
                  </TableCell>
                </TableRow>
  
                <TableRow>
                  <TableCell>Free Premium Access</TableCell>
                  <TableCell>
                    <TextField
                      label="Enter Free Premium Access. Example: 1 month, 2 month, 1 year"
                      variant="outlined"
                      fullWidth
                      value={freePremiumAccess}
                      onChange={(e) => setFreePremiumAccess(e.target.value)}
                    />
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
  
          {/* Submit Button */}
          <Box display="flex" justifyContent="center" style={{ marginTop: '20px' }}>
            <Button variant="contained" color="primary" onClick={submit}>
              Submit
            </Button>
          </Box>
        </Box>

        <ToastContainer />
      </Container>
    );
}
export default EstoreTemplate;
